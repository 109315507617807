<template>
  <div class="basket-item">
    <div class="area-separator"/>

    <div class="area-image">
      <a :href="productUrl" @click="clickHandler">
        <img v-if="item.product.image" :src="item.product.image" :alt="item.product.name"/>
        <aspect-ratio v-else ratio="4x3" class="is-fallback-image">
          <lazy-icon icon="images"/>
        </aspect-ratio>
      </a>
    </div>

    <div v-if="module.showItemActionButtons && $root.isMobile" class="area-actions">
      <div class="item-actions">
        <a href="#" @click="deleteItem"><lazy-icon icon="trash"/></a>
      </div>
    </div>

    <div class="area-description">
      <p><a :href="productUrl" ref="link" @click="clickHandler"><span class="item-name">{{ item.product.model }}</span> {{ item.product.name }} <small v-if="item.product.titel2">{{ item.product.titel2 }}</small></a></p>
    </div>

    <div v-if="$root.isMobile" class="area-configuration">
      <item-characteristics
        :translationsPath="`${tPath}.items.item`"
        :item="item"/>

      <delivery-state
        v-if="module.showItemDeliveryState"
        :is-store-clerk="isStoreClerk"
        :is-discontinued="item.product.isDiscontinued"
        :translationsPath="`${tPath}.items.item`"
        :state-id="item.product.deliveryStateId"
        :stock-amount="item.product.stockAmount"
        :restock-time="item.product.restockTime"
      />

      <product-properties
        :translationsPath="`${tPath}.items.item`"
        :product="item.product"
      />

      <div v-if="showStoreClerkOptions" class="store-clerk-actions">
        <a href="#" class="btn btn-link p-0" @click.prevent="toggleStoreClerkDiscount">
          <template v-if="!haveStoreClerkDiscountOptions">{{ $t(`${tPath}.items.item.addstoreclerkdiscount`) }}</template>
          <template v-else>{{ $t(`${tPath}.items.item.removestoreclerkdiscount`) }}</template>
        </a>
      </div>
    </div>

    <div v-if="module.showItemQuantity" class="area-quantity">
      <b-row v-if="$root.isMobile" no-gutter align-h="between" align-v="center">
        <b-col cols="auto">{{ $t(`${tPath}.items.header.quantity`) }}</b-col>
        <b-col cols="auto">
          <control-spinbutton
            v-if="module.showItemQuantityButton && item.product.familyId !== FAMILY_GIFTCARDS"
            v-model="itemQuantity"
            :size="$root.isMobile ? 'sm' : 'xs'"
            class="mb-0"
            @change="updateItem"
          />

          <span v-else>{{ itemQuantity }}</span>
        </b-col>
      </b-row>

      <template v-else>
        <control-spinbutton
          v-if="module.showItemQuantityButton && item.product.familyId !== FAMILY_GIFTCARDS"
          :max="!isStoreClerk && item.product.isDiscontinued ? item.product.stockAmount : 10000"
          v-model="itemQuantity"
          :size="$root.isMobile ? 'sm' : 'xs'"
          class="mb-0"
          @change="updateItem"
        />

        <span v-else>{{ itemQuantity }}</span>
      </template>
    </div>

    <div class="area-price">
      <b-row v-if="$root.isMobile" no-gutter align-h="between" align-v="center">
        <b-col cols="auto">{{ $t(`${tPath}.items.header.price`) }}</b-col>
        <b-col cols="auto">
          <c-product-price :productId="item.product.id" :priceinfo="priceInfo" :configuration="item.configuration" alginH="end" hideVatInfo inherit/>
        </b-col>
      </b-row>

      <template v-else>
        <c-product-price :productId="item.product.id" :priceinfo="priceInfo" :configuration="item.configuration" alginH="end" hideVatInfo inherit/>
      </template>
    </div>

    <div v-if="showItemDiscount" class="area-discount">
      <b-row v-if="$root.isMobile" no-gutter align-h="between" align-v="center">
        <b-col cols="auto">{{ $t(`${tPath}.items.header.discount`) }}</b-col>
        <b-col cols="auto">
          <template v-if="item.product.priceInfo.discountPercentage > 0">{{ item.product.priceInfo.discountPercentage }}%</template>
        </b-col>
      </b-row>

      <template v-else>
        <template v-if="item.product.priceInfo.discountPercentage > 0">{{ item.product.priceInfo.discountPercentage }}%</template>
      </template>
    </div>

    <div v-if="module.showItemTotalprice" class="area-totalprice">
      <b-row v-if="$root.isMobile" no-gutter align-h="between" align-v="center">
        <b-col cols="auto">{{ $t(`${tPath}.items.header.totalprice`) }}</b-col>
        <b-col cols="auto">
          <c-product-price :productId="item.product.id" :priceinfo="priceInfo" :configuration="item.configuration" :quantity="item.quantity" alginH="end" hideVatInfo inherit/>
        </b-col>
      </b-row>

      <template v-else>
        <c-product-price :productId="item.product.id" :priceinfo="priceInfo" :configuration="item.configuration" :quantity="item.quantity" alginH="end" hideVatInfo inherit/>
      </template>
    </div>

    <template v-if="!$root.isMobile">
      <div class="area-configuration">
        <item-characteristics
          :translationsPath="`${tPath}.items.item`"
          :item="item"/>

        <delivery-state
          v-if="module.showItemDeliveryState"
          :is-store-clerk="isStoreClerk"
          :is-discontinued="item.product.isDiscontinued"
          :translationsPath="`${tPath}.items.item`"
          :state-id="item.product.deliveryStateId"
          :stock-amount="item.product.stockAmount"
          :restock-time="item.product.restockTime"
        />

        <product-properties
          :translationsPath="`${tPath}.items.item`"
          :product="item.product"
        />

        <div v-if="issuedGiftCard !== null">
          <a href="#" @click.prevent="downloadGiftCard">{{ $t(`${tPath}.items.item.downloadgiftcard`) }}</a>
        </div>

        <div v-if="showStoreClerkOptions" class="store-clerk-actions">
          <a href="#" class="btn btn-link p-0" @click.prevent="toggleStoreClerkDiscount">
            <template v-if="!haveStoreClerkDiscountOptions">{{ $t(`${tPath}.items.item.addstoreclerkdiscount`) }}</template>
            <template v-else>{{ $t(`${tPath}.items.item.removestoreclerkdiscount`) }}</template>
          </a>
        </div>
      </div>

      <div v-if="module.showItemActionButtons" class="area-actions">
        <div class="item-actions">
          <a href="#" @click.prevent="deleteItem" class="delete"><lazy-icon icon="trash"/></a>
        </div>
      </div>
    </template>

    <div v-if="module.showItemServiceButtons" class="area-services d-print-none">
      <div class="btn-list text-right">
        <slot name="basketitemservicebuttons" v-bind="{
          addToBasket,
          basketItem: item
        }"/>
      </div>
    </div>

    <div v-if="module.showStillRequiredProducts && item.stillRequiredProducts" class="area-still-required-products d-print-none">
      <div class="alert alert-warning">
        <h6>{{ item.stillRequiredProducts.length === 1 ? $t(`${tPath}.items.item.requiredproducts.title.single`) : $t(`${tPath}.items.item.requiredproducts.title.multi`) }}</h6>
        <c-product-item v-for="(requiredProduct) in item.stillRequiredProducts"
                        :key="`RequiredProduct_${requiredProduct.id}`"
                        :product="requiredProduct"
                        :hide-config="true"
                        :hide-info="false"
                        :link-product-text-and-image="true"
                        :blank="false"
                        :inline="true"
                        :translationsPath="`${tPath}.items.item.requiredproducts`">
          <template v-slot:actions>
            <c-product-shoppingcart-button :product-id="requiredProduct.id"
                                          gtm-list="Product Detail Page"
                                          :quantityinfo="{ min: 1, max: 1000, step: 1, initialStep: 1 }"
                                          :small="true"
                                          :reloadFullBasketOnAdd="true"
                                          :translationsPath="`${tPath}.items.item.requiredproducts`">
              <lazy-icon icon="cart"/>
            </c-product-shoppingcart-button>
          </template>
        </c-product-item>
      </div>
    </div>

    <template v-if="haveStoreClerkDiscountOptions">
      <div class="area-separator half"/>
      <div class="area-store-clerk-discount-options">
        <store-clerk-discount
              :translationsPath="`${tPath}.items.item`"
              v-model="itemConfiguration"
              :itemId="item.basketItemId"
              :writeable="module.itemDiscountWriteable"
              @change="updateItem" />
      </div>
    </template>

    <div class="area-separator"/>
  </div>
</template>

<script>
import { COMPONENT_BASKET_MODULES } from '@/constants'

import ItemCharacteristics from '@/components/private/ItemCharacteristics'
import DeliveryState from '@/components/public/product/ProductDeliveryState'
import AspectRatio from '@/components/private/AspectRatio'
import ControlSpinbutton from '@/components/private/forms/ControlSpinbutton'
import ProductProperties from '@/components/private/ProductProperties'

import StoreClerkDiscount from '@/components/private/StoreClerkDiscount'

import http from '@/$plugins/http'

const DEFAULT_MODULENAME = Object.keys(COMPONENT_BASKET_MODULES).find(mKey => COMPONENT_BASKET_MODULES[mKey].isDefault)
const FAMILY_GIFTCARDS = 49

export default {
  name: 'BasketItem',
  components: {
    ItemCharacteristics,
    DeliveryState,
    AspectRatio,
    ControlSpinbutton,
    StoreClerkDiscount,
    ProductProperties
  },
  props: {
    moduleName: {
      type: String,
      default: DEFAULT_MODULENAME
    },
    item: {
      type: Object,
      required: true
    },
    issuedGiftCard: {
      type: Object,
      default: null
    },
    showItemDiscount: {
      type: Boolean,
      default: false
    },
    showStoreClerkOptions: {
      type: Boolean,
      default: false
    },
    isStoreClerk: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      itemQuantity: this.item.quantity,
      itemConfiguration: this.item.configuration,
      productUrl: `${this.$store.getters['gui/urls:getType']('product')}${this.item.product.urlSlug}`,
      updateItemTimeout: null,
      FAMILY_GIFTCARDS: FAMILY_GIFTCARDS
    }
  },
  computed: {
    module() {
      return Object.assign({}, COMPONENT_BASKET_MODULES.base, COMPONENT_BASKET_MODULES[this.moduleName] ?? {})
    },
    priceInfo() {
      const priceInfo = this.item.product.priceInfo
      return priceInfo
    },
    haveStoreClerkDiscountOptions() {
      return (this.itemConfiguration.customDiscountText !== undefined && this.itemConfiguration.customDiscountText !== null) ||
        (this.itemConfiguration.customDiscountType !== undefined && this.itemConfiguration.customDiscountType !== null) ||
        (this.itemConfiguration.customDiscountValue !== undefined && this.itemConfiguration.customDiscountValue !== null)
    }
  },
  methods: {
    clickHandler(e) {
      if (e.isTrusted && this.$store.getters['gtm/isAvailable']) {
        e.preventDefault()

        this.$store.dispatch('gtm/productClick', {
          list: this.module.gtm.list,
          product: {
            id: this.item.product.id,
            model: this.item.product.model,
            priceInfo: this.item.product.priceInfo,
            categories: this.item.product.categories,
            characteristics: this.item.product.characteristics
          },
          callback: () => {
            this.$refs.link.dispatchEvent(new MouseEvent('click', { ctrlKey: e.ctrlKey, shiftKey: e.shiftKey, altKey: e.altKey }))
          }
        })
      }
    },
    toggleStoreClerkDiscount() {
      if (!this.haveStoreClerkDiscountOptions) {
        this.item.configuration.customDiscountType = 'ValueDiscount'
        this.item.configuration.customDiscountValue = 0
      } else {
        this.item.configuration.customDiscountText = null
        this.item.configuration.customDiscountType = null
        this.item.configuration.customDiscountValue = null
      }

      this.updateItem()
    },
    downloadGiftCard() {
      http({
        method: 'get',
        url: `order/api/giftcards/download/${this.issuedGiftCard.orderId}/${this.issuedGiftCard.basketItemId}`,
        responseType: 'blob' // Important
      }).then(response => {
        // Create a new Blob object using the response data of the file
        const file = new Blob([response.data], { type: 'application/pdf' })

        // Create a URL for the file
        const fileURL = URL.createObjectURL(file)

        // Extract filename from the header
        const contentDisposition = response.headers['content-disposition']
        let filename = 'default-filename.pdf'
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
        const matches = filenameRegex.exec(contentDisposition)
        if (matches != null && matches[1]) {
          filename = matches[1].replace(/['"]/g, '')
        }

        // Create a temporary anchor element and trigger a download
        const link = document.createElement('a')
        link.href = fileURL
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()

        // Clean up and revoke the object URL
        document.body.removeChild(link)
        URL.revokeObjectURL(fileURL)
      })
    },
    addToBasket() {
      this.$store.dispatch(`${this.module.name}/${this.module.actions.addItem}`, {
        productId: this.item.product.id,
        quantity: this.item.quantity,
        configuration: this.item.configuration
      })
    },
    updateItem() {
      if (this.updateItemTimeout) clearTimeout(this.updateItemTimeout)

      this.updateItemTimeout = setTimeout(() => {
        this.$emit('basketitem:update', { id: this.item.basketItemId, quantity: this.itemQuantity, configuration: this.itemConfiguration })
      }, 250)
    },
    deleteItem() {
      this.$emit('basketitem:delete', { id: this.item.basketItemId })
    }
  },
  watch: {
    '$props.item.quantity'() {
      this.itemQuantity = this.item.quantity
    },
    '$props.item.configuration'() {
      this.itemConfiguration = this.item.configuration
    }
  }
}
</script>

<style lang="scss">
// mobile

$basketitem-separator-gap-y: $spacer * 0.4 !default;
$basketitem-separator-border: map-get($borders, 'bold') !default;

$basketitem-image-width: 120px !default;
$basketitem-img-fallback-size: 100% !default;
$basketitem-img-fallback-color: $imgfallback !default;

$basketitem-description-name-font-weight: $font-weight-bold !default;
$basketitem-description-name-text-transform: uppercase !default;

$basketitem-configuration-gap-y: 0 !default;
$basketitem-configuration-gap-x: $spacer * 1.2 !default;

$basketitem-totalprice-font-weight: $font-weight-bold !default;

$basketitem-actions-gap-y: $spacer * 1 !default;
$basketitem-actions-gap-x: $basketitem-actions-gap-y !default;
$basketitem-actions-font-size: $font-size-base * 1.25 !default;

// tablet

$basketitem-tablet: $tablet-breakpoint !default;

$basketitem-tablet-image-width: 100px !default;

$basketitem-tablet-actions-gap-y: $spacer * 0.4 !default;
$basketitem-tablet-actions-gap-x: $spacer * 1.5 !default;
$basketitem-tablet-actions-font-size: 1rem !default;

.basket {
  .basket-items {
    .basket-item {
      display: contents;

      .area-separator {
        margin-top: $basketitem-separator-gap-y;
        margin-bottom: $basketitem-separator-gap-y;
        border-top: $basketitem-separator-border;

        &.half {
          border-top-width: 1px;
        }
      }

      .area-image {
        a {
          display: block;
          color: inherit;
          text-decoration: none;

          img,
          .is-fallback-image {
            display: block;
            width: $basketitem-image-width;
            max-width: none;

            .bi {
              display: block;
              margin: auto;
              width: $basketitem-img-fallback-size;
              height: $basketitem-img-fallback-size;
              color: $basketitem-img-fallback-color;
            }
          }
        }
      }

      .area-description {
        a {
          display: block;
          color: inherit;
          text-decoration: none;

          .item-name {
            font-weight: $basketitem-description-name-font-weight;
            text-transform: $basketitem-description-name-text-transform;
          }
        }
      }

      .area-configuration {
        .item-configuration {
          display: flex;
          flex-wrap: wrap;
          margin: ($basketitem-configuration-gap-y * -0.5) ($basketitem-configuration-gap-x * -0.5);

          span {
            display: block;
            padding: ($basketitem-configuration-gap-y * 0.5) ($basketitem-configuration-gap-x * 0.5);
          }
        }

        .store-clerk-actions {
          display: flex;
          flex-wrap: wrap;
        }
      }

      .area-actions {
        .item-actions {
          display: flex;
          flex-direction: column-reverse;
          margin: ($basketitem-actions-gap-y * -0.5) ($basketitem-actions-gap-x * -0.5);

          a {
            display: block;
            padding: ($basketitem-actions-gap-y * 0.5) ($basketitem-actions-gap-x * 0.5);
            font-size: $basketitem-actions-font-size;
          }
        }
      }

      .area-store-clerk-discount-options {
        grid-column: 2/span 5;
        grid-row: auto;
      }

      .area-totalprice {
        font-weight: $basketitem-totalprice-font-weight;
      }

      .area-still-required-products {
        grid-column: 1 / span 6;
        grid-row: auto;
      }

      + .basket-item {
        .area-separator {
          &:first-child {
            display: none;
          }
        }
      }

      @include media-breakpoint-up($basketitem-tablet) {
        .area-image {
          a {

            img,
            .is-fallback-image {
              margin: 0;
              width: $basketitem-tablet-image-width;
            }
          }
        }

        .area-price,
        .area-totalprice {
          white-space: nowrap;
        }

        .area-actions {
          .item-actions {
            flex-direction: row;
            flex-wrap: wrap;
            margin: ($basketitem-tablet-actions-gap-y * -0.5) ($basketitem-tablet-actions-gap-x * -0.5);

            a {
              padding: ($basketitem-tablet-actions-gap-y * 0.5) ($basketitem-tablet-actions-gap-x * 0.5);
              font-size: $basketitem-tablet-actions-font-size;
            }
          }
        }

        .area-still-required-products {
          grid-column: 2 / span 5;
          grid-row: auto;
        }
      }
    }
  }
}
</style>
